<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Payments</p>
          <!-- <p class="subtitle">Add payments</p> -->
        </div>
        <div class="column"
          v-if="!readOnly">
          <div class="field is-pulled-right">
            <p class="control">
              <button class="button is-primary tooltip is-tooltip-topright"
                :disabled="entity.totalAmount === 0 || balance <= 0"
                @click="addPayment()"
                data-tooltip="Add new payment">
                <span class="icon">
                  <i class="mdi mdi-plus" />
                </span>
                <span>Add</span>
              </button>
            </p>
          </div>
        </div>
      </div>
      <!-- <table v-if="!readOnly" -->
      <table class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Date</th>
            <th>
              <div class="has-text-right">Amount</div>
            </th>
            <th>
              Cheque No
            </th>
            <th>
              Bank Account
            </th>
            <th />
          </tr>
        </thead>
        <tbody v-if="haveNewItems">
          <tr v-for="(item, index) in payments"
            :key="item.id"
            :class="{ hiddenRow: !item.isNew }"
            v-show="!item.isDeleted">
            <td>
              <v-date-picker v-model="item.payDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale"
                @input="payDateInputEvent(item.payDate, $event)"
                :timezone="$filters.getIanaTimezone()">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="From Date"
                        :value="inputValue"
                        v-on="inputEvents">
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
              <!-- <fq-date-picker :id="'paydate' +index"
                :show-error="false"
                :v="$v.$each[index].payDate"
                v-model="item.payDate" /> -->
            </td>
            <td>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  :class="{ 'is-danger' : $v.$each[index].paid.$invalid }"
                  :min="0"
                  :precision="2"
                  :ref="'inputPaid'"
                  v-model.number="item.paid"
                  @drop.native="onDrop"
                  @input.native="onAmountChange($event.target.value, index)"
                  v-focus-inserted />
              </div>
            </td>
            <td>
              <div class="control">
                <input class="input"
                  v-model="item.cheque">
              </div>
            </td>
            <td>
              <div class="control is-expanded">
                <multiselect v-if="glAccountListCombo"
                  :class="{ 'is-danger' : $v.$each[index].bankAccount.$invalid }"
                  v-model="item.bankAccount"
                  :options="glAccountListCombo"
                  placeholder="Select bank account"
                  track-by="accountNo"
                  label="displayName"
                  :allow-empty="false"
                  :show-labels="false" />
              </div>
            </td>

            <td class="has-vertical-middle has-text-centered">
              <a class="button is-danger is-small is-inverted"
                v-if="item.isNew"
                @click="deletePayment(index, $event)">
                <span class="icon is-medium">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr v-for="(item) in payments"
            :key="item.id"
            v-show="!item.isDeleted && !item.isNew">
            <td class="pad-date">
              {{ $filters.formatDateTimezone(item.payDate, $userInfo.locale) }}
            </td>
            <td class="has-text-right pad-money">
              {{ item.paid | formatCurrency($userInfo.locale) }}
            </td>
            <td class="pad-text">
              {{ item.cheque }}
            </td>
            <td class="pad-text"
              colspan="2">
              {{ item.bankAccount ? item.bankAccount.displayName : '' }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="has-text-right">
              <strong>Invoice Total</strong>
            </td>
            <td class="has-text-right">
              <strong>{{ entity.totalAmount | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td colspan="3" />
          </tr>
          <tr>
            <td class="has-text-right">
              <strong>Payment Total</strong>
            </td>
            <td class="has-text-right">
              <strong>{{ totalPaid | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td colspan="3" />
          </tr>
          <tr>
            <td class="has-text-right">
              <strong>Balance</strong>
            </td>
            <td class="has-text-right">
              <strong>{{ entity.totalAmount - totalPaid | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td colspan="3">
              <span class="help is-danger"
                v-if="totalPaid > entity.totalAmount && totalPaid !== 0">
                Warning! Total amount paid is more than payable amount of "{{ entity.totalAmount | formatCurrency($userInfo.locale) }}"
              </span>
            </td>
          </tr>
        </tfoot>
      </table>
    </article>
  </div>
</template>
<script>
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import StoreMixin from './storeMixin'
import SundryCreditorService from './SundryCreditorService'
import { InputValidationDirective } from '@/components/directives'
import { calcBeforeGst, roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import { NumberFiltersMixin, DateTimeFiltersMixin } from '@/components/mixins/filters'
import { FocusInserted } from '@/components/directives'

export default {
  name: 'SundryCreditorPayments',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect
  },
  directives: {
    FocusInserted
  },
  mixins: [InputValidationDirective, NumberFiltersMixin, DateTimeFiltersMixin, StoreMixin],
  props: {
    entity: null
  },
  data() {
    return {
      glAccountListCombo: [],
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    $v() {
      return this.$vv.entity.payments || this.$v
    },
    payments() {
      return this.entity.payments
    },
    totalPaid() {
      let rs = 0
      this.payments.forEach((d) => {
        rs += d.paid
      })
      return rs
    },
    balance() {
      return this.entity.totalAmount - this.totalPaid
    },
    readOnly() {
      // return !this.entity.gstPaidDate === false
      return (!this.entity.gstPaidDate && !this.readOnlyView) === false
    },
    haveNewItems() {
      return this.payments.some((d) => d.isNew)
    }
  },
  async mounted() {
    const req = await SundryCreditorService.getGlBankAccountListComboProto()
    this.glAccountListCombo = req
    if (this.$v.$invalid) {
      // this.focusLastInput()
    }
  },
  methods: {
    onDrop(e) {
      e.preventDefault()
    },
    onNativeInput(index, event) {},
    // focusLastInput() {
    //   this.$nextTick(() => {
    //     let index = this.payments.length - 1
    //     let input = this.$refs.inputPaid[index].$el
    //     input.focus()
    //   })
    // },
    onAmountChange(value, index) {
      const beforeGst = roundAwayFromZero(calcBeforeGst(value, this.entity.gstRate), 2)
      this.entity.payments[index].gstAmount = roundAwayFromZero(value - beforeGst, 2)
    },
    addPayment() {
      if (this.$v.$invalid) {
        this.$notification.validationError('Payment', 'Validation error. Please fix before adding more payment.')
        return
      }

      this.entity.payments.push({
        isNew: true,
        cheque: '',
        gstAmount: 0,
        id: this.$guid.empty(),
        paid: 0,
        payDate: new Date().toISOString().split('.')[0] + 'Z'
        // bankAccount: {
        //   accountId: '',
        //   accountNo: '',
        //   accountType: '',
        //   displayName: ''
        // }
      })

      this.$v.$touch()

      // this.focusLastInput()
    },
    deletePayment(index, event) {
      this.entity.payments.splice(index, 1)
      this.$v.$touch()
    },
    payDateInputEvent(payDate , event) {
      payDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>
<style lang="scss" scoped>
.hiddenRow {
  border-collapse: collapse;
  display: none;
}
.pad-date {
  padding-left: 44px !important;
}
.pad-money {
  padding-right: 20px !important;
}
.pad-text {
  padding-left: 20px !important;
}
.is-divider {
  margin: 1.2em 0;
}
tfoot {
  tr {
    td {
      padding-right: 18px !important;
    }
  }
}
</style>

<template>
  <div class="tile is-parent is-half">
    <article class="tile is-child box detail-page-tile">
      <div class="columns is-gapless">
        <div class="column">
          <p class="title">Invoice Items</p>
          <!-- <p class="subtitle">Add items</p> -->
        </div>
        <div class="column"
          v-if="!readOnly">
          <div class="field is-pulled-right">
            <p class="control">
              <button class="button is-primary tooltip is-tooltip-topright"
                @click="addItem()"
                data-tooltip="Add new invoice item">
                <span class="icon">
                  <i class="mdi mdi-plus" />
                </span>
                <span>Add</span>
              </button>
            </p>
          </div>
        </div>
      </div>

      <table v-if="!readOnly && glAccountListCombo"
        class="table is-bordered is-striped is-narrow is-fullwidth">
        <thead>
          <tr>
            <th>Description</th>
            <th>
              <div class="has-text-right">Before GST</div>
            </th>
            <th>
              <div class="has-text-right">After GST</div>
            </th>
            <th>
              <div class="has-text-right">GST</div>
            </th>
            <th>
              <div class="has-text-centered">G/L Account</div>
            </th>
            <th>
              <div class="has-text-centered">Capital Item</div>
            </th>
            <th>
              <div class="has-text-centered">GST Report</div>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items"
            :key="item.id"
            v-show="!item.isDeleted">
            <td>
              <div class="control">
                <input class="input"
                  placeholder=""
                  v-model="item.itemDesc">
              </div>
            </td>
            <td>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  :class="{ 'is-danger' : $v.$each[index].amountExGst.$invalid }"
                  :minus="true"
                  :precision="2"
                  :ref="'inputAmtExGst'"
                  @drop.native="onDrop"
                  v-model.number="item.amountExGst"
                  @input.native="emitChanges('exGst', $event.target.value, index)" />
              </div>
            </td>
            <td>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  :class="{ 'is-danger' : $v.$each[index].amountIncGst.$invalid }"
                  :minus="true"
                  :precision="2"
                  v-model="item.amountIncGst"
                  @drop.native="onDrop"
                  @input.native="emitChanges('incGst', $event.target.value, index)" />
              </div>
            </td>
            <td>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  :minus="true"
                  :precision="2"
                  v-model="item.gst"
                  @drop.native="onDrop"
                  @input.native="emitChanges('gst', $event.target.value, index)" />
              </div>
            </td>
            <td>
              <div class="control is-expanded">
                <multiselect v-if="glAccountListCombo"
                  v-model="item.itemGlCode"
                  @select="glAccountSelected($event, item)"
                  :options="glAccountListCombo"
                  placeholder="Select account"
                  label="displayName"
                  track-by="accountNo"
                  :allow-empty="false" />
              </div>
            </td>
            <td class="has-vertical-middle has-text-centered">
              <input class="is-checkradio is-circle is-primary"
                :class="{ 'has-background-color': item.isCapitalItem }"
                :id="`capital${index}`"
                :name="`capital${index}`"
                type="checkbox"
                @change="updateInvoiceCapital()"
                :disabled="!capitalEnabled(item)"
                v-model="item.isCapitalItem">
              <label :for="`capital${index}`"
                data-label-empty />
            </td>
            <td class="has-vertical-middle has-text-centered">
              <span class="icon has-text-success tooltip is-tooltip-topright"
                v-if="item.isGstReport"
                data-tooltip="GST amount more than 0 ">
                <i class="mdi mdi-24px mdi-check" />
              </span>
            </td>
            <td class="has-vertical-middle
              has-text-centered
              ">
              <a class="button
              is-danger
              is-small
              is-inverted
              "
                @click="deleteItem(index,
                                   $event)
                ">
                <span class="icon
              is-medium
              ">
                  <i class="mdi
              mdi-delete
              mdi-24px
              " />
                </span>
              </a>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="has-text-right
              ">
              <strong>Totals</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalBeforeGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalAfterGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td colspan="4
              " />
          </tr>
        </tfoot>
      </table>

      <table v-else
        class="table
              is-bordered
              is-striped
              is-narrow
              is-fullwidth
              ">
        <thead>
          <tr>
            <th>Description</th>
            <th>
              <div class="has-text-right
              ">Before GST</div>
            </th>
            <th>
              <div class="has-text-right
              ">After GST</div>
            </th>
            <th>
              <div class="has-text-right
              ">GST</div>
            </th>
            <th>
              <div class="has-text-centered
              ">G/L Account</div>
            </th>
            <th>
              <div class="has-text-centered
              ">Capital Item</div>
            </th>
            <th>
              <div class="has-text-centered
              ">GST Report</div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item) in items"
            :key="item.id"
            v-show="!item.isDeleted">
            <td>
              {{ item.itemDesc }}
            </td>
            <td class="has-text-right
              ">
              {{ item.amountExGst | formatCurrency($userInfo.locale) }}
            </td>
            <td class="has-text-right
              ">
              {{ item.amountIncGst | formatCurrency($userInfo.locale) }}
            </td>
            <td class="has-text-right
              ">
              {{ item.gst | formatCurrency($userInfo.locale) }}
            </td>
            <td>
              {{ item.itemGlCode.displayName }}
            </td>
            <td class="has-vertical-middle
              has-text-centered
              ">
              <span class="icon
              has-text-success
              "
                v-if="item.isCapitalItem
                ">
                <i class="mdi
              mdi-24px
              mdi-check
              " />
              </span>
            </td>
            <td class="has-vertical-middle
              has-text-centered
              ">
              <span class="icon
              has-text-success
              "
                v-if="item.isGstReport
                ">
                <i class="mdi
              mdi-24px
              mdi-check
              " />
              </span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="has-text-right
              ">
              <strong>Totals</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalBeforeGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalAfterGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td class="has-text-right
              ">
              <strong>{{ totalGst | formatCurrency($userInfo.locale) }}</strong>
            </td>
            <td colspan="3
              " />
          </tr>
        </tfoot>
      </table>
      <!-- <pre>
        {{$v}}
      </pre> -->
    </article>

  </div>
</template>
<script>
// TODO:
// 1. How does total sum affect invoice sum?
// 2. Do we allow them to change it since it's paid, what if the figure is below the paid sum?
import VueNumeric from '@/components/VueNumeric'
import Multiselect from 'vue-multiselect'
import VendorService from '@/views/vendor/VendorService'
import { calcGst, calcBeforeGst } from '@/components/utils/AccountingFunctions'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import _debounce from 'lodash/debounce'
import StoreMixin from './storeMixin'
import SundryCreditorRoutes from './route-types'

export default {
  name: 'SundryCreditorItems',
  inject: ['$vv'],
  components: {
    VueNumeric,
    Multiselect
  },
  mixins: [NumberFiltersMixin, StoreMixin],
  props: {
    entity: null
  },
  data() {
    return {
      glAccountListCombo: []
    }
  },
  computed: {
    $v() {
      return this.$vv.entity.items || this.$v
    },
    items() {
      return this.entity.items
    },
    totalBeforeGst() {
      let rs = 0
      this.items.forEach((d) => {
        rs += d.amountExGst
      })
      return rs
    },
    totalAfterGst() {
      let rs = 0
      this.items.forEach((d) => {
        rs += d.amountIncGst
      })
      return rs
    },
    totalGst() {
      let rs = 0
      this.items.forEach((d) => {
        rs += d.gst
      })
      return rs
    },
    routeTypes() {
      return SundryCreditorRoutes
    },
    readOnly() {
      // return !this.entity.gstPaidDate === false
      return (!this.entity.gstPaidDate && !this.readOnlyView) === false
    }
  },
  async mounted() {
    const req = await VendorService.getGlAccountListComboProto()
    this.glAccountListCombo = req

    if (this.$v.$invalid) {
      this.focusLastInput()
    }
  },
  methods: {
    onDrop(e) {
      e.preventDefault()
    },
    onNativeInput(index, event) {},
    focusLastInput() {
      this.$nextTick(() => {
        let index = this.items.length - 1
        let input = this.$refs.inputAmtExGst[index].$el
        input.focus()
      })
    },
    addItem() {
      if (this.$v.$invalid) {
        this.$notification.validationError('Items', 'Validation error. Please fix before adding more item.')
        return
      }

      this.entity.items.push({
        itemGlCode: this.entity.glCode,
        isCapitalItem: this.entity.isCapital
      })

      this.$v.$touch()

      this.focusLastInput()
    },
    deleteItem(index, event) {
      this.entity.items.splice(index, 1)

      this.$emit('amountChanged', {
        exGst: this.totalBeforeGst,
        incGst: this.totalAfterGst,
        gst: this.totalGst
      })

      this.$v.$touch()
    },
    capitalEnabled(item) {
      const isAsset = item.itemGlCode.accountType === 'ASS'

      return isAsset || (!isAsset && item.amountIncGst >= 1000)
    },
    glAccountSelected(option, item) {
      if (this.capitalEnabled) {
        const isAsset = option.accountType === 'ASS'
        item.isCapitalItem = isAsset
      } else {
        item.isCapitalItem = false
      }

      this.updateInvoiceCapital()
    },
    updateInvoiceCapital() {
      console.log('capital changed')
      if (this.items.some((d) => d.isCapitalItem === true)) {
        this.entity.isCapital = true
      } else {
        this.entity.isCapital = false
      }
    },
    emitChanges: _debounce(function (type, value, index) {
      const item = this.items[index]
      const decimalPattern = /^-?\d+(\.\d*)?$/
      let isValid = decimalPattern.test(value)
      // alert(isValid)
      if (isValid) {
        switch (type) {
          case 'gst':
            item.amountIncGst = +item.amountExGst + +value
            // item.amountExGst = value / (this.entity.gstRate / 100)
            // item.amountIncGst = +value + +item.amountExGst
            break
          case 'incGst':
            item.amountExGst = calcBeforeGst(value, this.entity.gstRate)
            item.gst = item.amountIncGst - item.amountExGst
            break
          case 'exGst':
            item.gst = calcGst(1, value, this.entity.gstRate)
            console.log(item.gst)
            item.amountIncGst = +value + +item.gst
            break
        }

        // update isGstReport
        item.isGstReport = item.gst > 0

        this.entity.isIncludeGst = this.items.some((d) => d.isGstReport === true)

        this.$emit('amountChanged', {
          exGst: this.totalBeforeGst,
          incGst: this.totalAfterGst,
          gst: this.totalGst
        })
      } else {
        switch (type) {
          // case 'gst':
          //   item.gst = calcGst(1, item.amountExGst, this.entity.gstRate)
          //   break
          case 'incGst':
            item.gst = calcGst(1, item.amountExGst, this.entity.gstRate)
            // console.log(item.gst)
            item.amountIncGst = +item.amountExGst + +item.gst
            break
          case 'exGst':
            // alert(item.amountExGst)
            item.amountExGst = calcBeforeGst(item.amountIncGst, this.entity.gstRate)
            item.gst = item.amountIncGst - item.amountExGst
            break
        }
      }
    }, 500)
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
tfoot {
  tr {
    td {
      padding-right: 20px !important;
    }
  }
}
</style>

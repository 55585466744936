import QuoteService from '@/views/quote/QuoteService'
import SundryCreditorService from './SundryCreditorService'
import { required, numeric, minLength, minValue } from 'vuelidate/lib/validators'
import contacts from '@/assets/validateForms/Contacts'
import { abnValidator, abnMinLength } from '@/components/validators'
import store from '@/store'

const countryCode = () => {
  const company = store.getters['company/company']
  return company.info.countryCode
}

const shouldValidateAbn = () => {
  if (countryCode() === 'AU') {
    return true
  } else {
    return false
  }
}

export default {
  provide: function () {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: [
      'entity.vendor',
      'entity.invoiceRef',
      'entity.glCode',
      'entity.invoiceDate',
      'entity.abn',
      'entity.accountNo',
      'entity.amount',
      'entity.gstAmount',
      'entity.totalAmount',
      'entity.quoteNo'
    ],
    itemsGroup: ['entity.items'],
    paymentsGroup: ['entity.payments'],
    contactsGroup: ['entity.contacts'],
    entity: {
      vendor: {
        required
      },
      invoiceRef: {
        required,
        async isValid(value) {
          if (!value || value === '' || !this.entity.vendor) {
            return true
          }
          var res = await SundryCreditorService.assertNoUsed(this.entity.id, this.entity.vendor.key, value)
          return !res.data
        }
      },
      invoiceDate: {
        required
      },
      glCode: {
        required
      },
      abn: {
        minLength: minLength(abnMinLength(countryCode())),
        validAbn: (value) => {
          if (shouldValidateAbn()) {
            return abnValidator(value)
          } else {
            return true
          }
        }
      },
      accountNo: {
        numeric
      },
      amount: {
        required
      },
      gstAmount: {
        required
      },
      totalAmount: {
        required
        // minValue: minValue(0.01) // ,
        // totalPaid: totalPaidValidator
      },
      quoteNo: {
        async isValid(value) {
          if (!value || value === '') {
            return true
          }

          var res = await QuoteService.isQuoteNoExists(value)

          return res.data
        }
      },
      items: {
        $each: {
          amountExGst: {
            required
          },
          amountIncGst: {
            required
          },
          gst: {
            required
          },
          itemGlCode: {
            required
          }
        }
      },
      payments: {
        $each: {
          payDate: {
            required
          },
          paid: {
            required,
            minValue: minValue(0.01)
          },
          bankAccount: {
            required
          }
        }
      },
      ...contacts
    },
    filter: {
      // dateFrom: {
      //   validDate: aussieDateValidator
      // },
      // dateTo: {
      //   validDate: aussieDateValidator
      // }
    }
  }
}
